import WatsonApi from "../backends/WatsonApi";
import {ReactiveContentItem} from "../stores/SchoolFeedStore";
import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {IView} from "../stores/InterfaceStore";
import {unstable_serialize} from "swr/infinite";
import {getCacheKeyFromCurrentRangeUrlQuery} from "../components/schoolfeed/SchoolFeedEventsDisplay";
import {getSchoolFeedInfiniteScrollUniqueKey} from "../components/schoolfeed/hooks/useSchoolFeedInfiniteScroll";
import {useSWRConfig} from "swr";
import {getQueries, isSchoolFeedStandalone} from "../utils/SchoolBlocksUtilities";

export function useCreateContentItem() {
    const {interfaceStore, organizationStore, gridStore} = useContext(StoreContext);
    const { mutate } = useSWRConfig();

    async function handleSave (contentItem, organization, post_to_facebook: boolean) {
        const client = await WatsonApi();
        const result = await client.apis.organizations.organizations_content_items_create({
            "organization_pk": organization.id,
            "post_to_facebook": post_to_facebook,
            data: contentItem,
        })
        const item = new ReactiveContentItem({...JSON.parse(result.data), organization});
        if (interfaceStore.view === IView.SCHOOLFEED_PAGE || (interfaceStore.view === IView.DEFAULT && isSchoolFeedStandalone())) {
            let contentType: ISchoolFeedContentType | null = null;
            if (item.is_news) contentType = "news";
            else if (item.is_file || item.is_folder) contentType = "files";
            else if (item.is_media) contentType = "media";
            else if (item.is_event) contentType = "events";
            else if (item.is_coursework) contentType = "coursework";

            if (contentType) {
                const urlQuery = getQueries(interfaceStore.currentFullUrl);
                if (contentType === "events") {
                    if (urlQuery.currentRangeStart && urlQuery.currentRangeEnd) {
                        await mutate(unstable_serialize((index, previousPageData) => {
                            return getCacheKeyFromCurrentRangeUrlQuery(urlQuery);
                        }))
                    }
                } else {
                    await mutate(unstable_serialize((index, previousPageData) => JSON.stringify({
                        key: getSchoolFeedInfiniteScrollUniqueKey(contentType as ISchoolFeedContentType),
                        page: 0,
                        following: urlQuery.following || "",
                        urlOrgId: urlQuery.orgId || "",
                    })));
                }
            }
        } else {
            // when creating an item from here, because of the complexity of shared_paths and
            // business logic of what items show up where, we can't really be sure what terrace
            // an item should show up on (if any), so it's safest to just revalidate everything
            const client = await WatsonApi();
            const result = await client.apis.organizations.organizations_read({
                organization_pk: organizationStore.currentOrganization.id,
                id: organizationStore.currentOrganization.id,
                expand: ["blocks"],
            });
            gridStore.setBlocks(JSON.parse(result.data).blocks);
        }
    }

    return { handleSave };
}
