import FontAwesome from "../utilities/FontAwesome";
import React, {useContext} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import {observer} from "mobx-react";
import LoginButtons from "../admin/sidebar/items/LoginButtons";

export const InitialLoadingIndicatorWrapper = observer((props: React.PropsWithChildren<{
    initialFetchIsLoading: boolean
    showInitialLoadingIndicator: boolean
    dataSize: number
    errorCode?: number
}>) => {
    const {schoolFeedStore, userStore} = useContext(StoreContext);

    if (props.errorCode && props.errorCode === 403) {
        let text;
        if (userStore.id) {
            text = "Sorry, you do not have permission to view this feed.";
        } else {
            text = "Please login to view this feed."
        }
        return <div className={"sb-content-width sb-no-filter-results-msg active"}>
            <div className="sb-content-width">
                <div>
                    <h3>{text}</h3>
                </div>
                {!userStore.id && <LoginButtons />}
            </div>
        </div>
    } else if (!props.initialFetchIsLoading && props.dataSize === 0) {
        return <div className={"sb-content-width sb-no-filter-results-msg active"}>
            <div className="sb-content-width">
                <div>
                    <h3>Sorry, but there was nothing found. Please try different filters.</h3>
                </div>
            </div>
        </div>
    } else if (props.initialFetchIsLoading && props.showInitialLoadingIndicator) {
        return <div className={"sb-content-width sb-no-filter-results-msg active"}>
            <div>
                <h3>One moment loading your results </h3>
                <FontAwesome prefix={"fas"} name={"fa-spinner"}
                             spin={schoolFeedStore.fetchStatus !== "SUCCESS"}/>
            </div>
        </div>
    } else {
        return <>{props.children}</>;
    }
})