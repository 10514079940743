import FlatIcon from "../FlatIcon";
import classNames from 'classnames';

import {getFormattedEventData} from "../../../utils/CleversiteUtilities";
import styles from "./styles/calendar.module.scss";
import {EditButton} from "../../blocks/TerracedGridTheme/EditButton";
import React, {useContext, useMemo} from "react";
import {Moment} from "moment";
import {shortenTextBlock} from "../../../utils/StringUtilities";
import {StoreContext} from "../../../stores/StoreLoader";
import {buildCleverFeedUrl, isTrueMultiDayEvent} from "../../../utils/SchoolBlocksUtilities";
import {IReactiveEventContentItem} from "../../../stores/SchoolFeedStore";
import {observer} from "mobx-react";
import ClickableLink from "../ClickableLink";

const Event = observer((props: {
    event: IReactiveEventContentItem,
    currentDate: Moment,
    onEventClick: (event: EventContentItem,
                   handleDelete?: (c: ContentItem) => void) => void,
    handleDelete: (contentItem: ContentItem) => void,
}) => {
    const {timeStr, endTime, startTime} = getFormattedEventData(props.event);
    const {interfaceStore, userStore} = useContext(StoreContext);

    const editButton = <>{userStore.isEditor &&
        <EditButton
            handleDelete={props.handleDelete}
            contentItem={props.event}
            className={styles.editButton}
        />}</>


    const shouldBeHighlighted = useMemo(() => {
        if (props.event.json_data.event.multi_day) {
            const start = props.event.json_data.event.start;
            const end = props.event.json_data.event.end;
            return props.currentDate.isBetween(start, end, "days", '[]');
        } else {
            return props.currentDate.format("MMM-D-YYYY") === startTime.format("MMM-D-YYYY");
        }
    }, [props.currentDate]);

    const dayEventClassName = classNames({
        [styles.dayEvent]: true,
        [styles.dayEventHidden]: !props.event.published,
        [styles.dayEventCurrentlySelectedDate]: shouldBeHighlighted,
    })

    return <li className={dayEventClassName} data-start-date={startTime.format("MMM-D-YYYY")}>
        <button onClick={() => props.onEventClick(props.event, props.handleDelete)}>
            {isTrueMultiDayEvent(props.event.json_data.event, startTime, endTime) ? <div className={`${styles.dayLabel} notranslate`}>
                <div>{startTime.format("MMM")}</div>
                <div>
                    {`${startTime.format("D")}-${(endTime as Moment).format("D")}`}
                </div>
            </div> : <div className={`${styles.dayLabel} notranslate`}>
                <div>{startTime.isValid() ? startTime.format("MMM") : ""}</div>
                <div style={{fontSize: "2.5rem"}}>{startTime.isValid() ? startTime.format("D") : ""}</div>
            </div>}
            <div className={styles.eventInfo}>
                <div className={styles.dayContent}>
                    <span className={styles.eventTitle}>{interfaceStore.breakpoint === "break-point-xs" ? shortenTextBlock(props.event.title, 45) : props.event.title}</span>
                    <div>
                        <FlatIcon name={'flaticon-clock'}/>{timeStr}
                    </div>
                </div>
                {<div className={styles.location}>
                    <ClickableLink
                        aria-label={`Link to ${props.event.organization.title}'s Events Feed`}
                        title={`Link to ${props.event.organization.title}'s Events Feed`}
                        onClick={(e) => e.stopPropagation()}
                        href={buildCleverFeedUrl(props.event.organization.id, 'events')}>
                        {props.event.organization.title}
                    </ClickableLink>
                    {props.event.json_data?.event?.location && <div>
                        <FlatIcon name={'flaticon-location'}/>
                        {interfaceStore.breakpoint === "break-point-xs" ? shortenTextBlock(props.event.json_data.event.location, 45) : props.event.json_data.event.location}
                    </div>}
                </div>}
            </div>
            {editButton}
        </button>
    </li>
})

export default Event;

